import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Membership from "../../models/Membership";
import dayjs from "dayjs";

export default function MembershipUsage() {
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(false)
    const init = async () => {
        setLoading(true)
        try {
            let result = await Membership.listMembership(7379354124444)
            console.log('isi ', result)
            setDataSource(result?.data)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }
    useEffect(() => {
        init()
    }, [])
    return (
        <>
            <Container className={'mt-5'}>


                <Row>
                    {
                        loading ? <div className={'text-center'}>
                                <span className="loaders"></span>
                            </div> :
                            dataSource?.map((v) => {
                                let statusMembership = {}
                                let validStart = dayjs(v.validStartDate).format('DD MMMM YYYY')
                                let validEnd = dayjs(v.validEndDate).format('DD MMMM YYYY')
                                if (v.status === 'ACTIVE') {
                                    statusMembership = {
                                        name: 'AKTIF',
                                        color: 'success'
                                    }
                                } else {
                                    statusMembership = {
                                        name: 'WAITING',
                                        color: 'warning'
                                    }
                                }
                                return (
                                    <>
                                        <div className="col-lg-4 mb-4">
                                            <Link style={{textDecoration: 'none'}} to={v?.transactionID}>
                                                <div
                                                    className="card"
                                                    style={{background: "linear-gradient(#101820, #1B74BA)"}}
                                                >
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-8">
                                                                <h5 className="text-white max-length">
                                                                    {v?.productTitle}
                                                                </h5>
                                                                <p style={{color: "#E8E8E8"}}>
                                                                    {validStart} - {validEnd}
                                                                </p>
                                                            </div>
                                                            <div className="col-lg-4 text-right">
                                                                <button
                                                                    type="button"
                                                                    className={`btn btn-${statusMembership?.color}`}
                                                                    style={{borderRadius: "1rem"}}
                                                                >
                                                                    Success
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="mt-3 d-flex"
                                                             style={{justifyContent: "space-between"}}>
                                                            <div>
                                                                <p className="float-left text-white">
                                                                    Paket {v?.variantTitle}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>

                                        </div>
                                    </>
                                )
                            })
                    }

                </Row>
            </Container>

        </>
    )
}