import logo from './logo.svg';
import {Button} from "react-bootstrap";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from "./components/reusable/ScrollToTop";
import {Helmet} from "react-helmet";
import MembershipUsage from "./components/pages/MembershipUsage";
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return (
        <>
            <Helmet>
                <title>Mainoutdoor Member</title>
                <meta name="description" content="Mie Kering Terbaik Nusantara"/>
            </Helmet>
            <Router>
                <Routes>
                    <Route path="/" element={<MembershipUsage/>}/>
                </Routes>
            </Router>
        </>

    );
}

export default App;
